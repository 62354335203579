import React, { memo, useCallback, useState } from 'react';
import Container from '../Container';
import './styles.scss';
import {
  IconFacebook24,
  IconInstagram24,
  IconTelegram24,
  IconVk24,
  IconYoutube24,
} from '@app/icons';
import { Link } from 'react-router-dom';
import { TenderCategory, UserType } from '@app/models';
import { ModalTenderCreate } from '@app/modals';
import { useUserContext } from '@app/providers';

interface Props {}

function Footer(props: Props) {
  const { user, showSignInModal, company } = useUserContext();
  const [tenderCategory, setTenderCategory] = useState<TenderCategory>(
    TenderCategory.CONTRACTOR
  );
  const [modalTenderCreateVisible, setModalTenderCreateVisible] =
    useState<boolean>(false);

  const closeModalTenderCreate = useCallback(() => {
    setModalTenderCreateVisible(false);
  }, []);

  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onClickRequest = useCallback(
    (category: TenderCategory) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!user.authenticated) {
        showSignInModal();

        return;
      }

      setTenderCategory(category);
      setModalTenderCreateVisible(true);
    },
    [showSignInModal, user]
  );

  return (
    <footer className="smr-footer">
      <Container>
        <div className="smr-footer__container">
          <div className="smr-footer__links">
            <p className="smr-footer__text">Заявка</p>
            {(!user || (!!user && user.type !== UserType.INDIVIDUAL)) && (
              <a
                href="#"
                className="smr-footer__link"
                onClick={onClickRequest(TenderCategory.CONTRACTOR)}
              >
                <span className="smr-footer__text">Заявка на СМР</span>
              </a>
            )}
            {(!user || (!!user && user.type !== UserType.INDIVIDUAL)) && (
              <a
                href="#"
                className="smr-footer__link"
                onClick={onClickRequest(TenderCategory.SUPPLIER)}
              >
                <span className="smr-footer__text">
                  Заявка на поставку материалов
                </span>
              </a>
            )}
            <a
              href="#"
              className="smr-footer__link"
              onClick={onClickRequest(TenderCategory.VEHICLE)}
            >
              <span className="smr-footer__text">Заявка на Спецтехнику</span>
            </a>
            <a
              href="#"
              className="smr-footer__link"
              onClick={onClickRequest(TenderCategory.WORKER)}
            >
              <span className="smr-footer__text">Заявка на Специалистов</span>
            </a>
          </div>
          <div className="smr-footer__links">
            <p className="smr-footer__text">Найти</p>
            <Link to="/" className="smr-footer__link">
              <span className="smr-footer__text">Найти СМР</span>
            </Link>
            <Link to="/suppliers" className="smr-footer__link">
              <span className="smr-footer__text">
                Найти поставку материалов
              </span>
            </Link>
            <Link to="/vehicles" className="smr-footer__link">
              <span className="smr-footer__text">Найти Спецтехнику</span>
            </Link>
            <Link to="/specialists" className="smr-footer__link">
              <span className="smr-footer__text">Найти Специалистов</span>
            </Link>
          </div>
          <div className="smr-footer__links">
            <p className="smr-footer__text">Информация</p>
            {/*<a href="#" className="smr-footer__link" onClick={onClick}>*/}
            {/*  <span className="smr-footer__text">Новости</span>*/}
            {/*</a>*/}
            <Link to="/about-us" className="smr-footer__link">
              <span className="smr-footer__text">О нас</span>
            </Link>
            <Link to="/privacy" className="smr-footer__link">
              <span className="smr-footer__text">
                Политика конфиденциальности
              </span>
            </Link>
            {/*<a href="#" className="smr-footer__link" onClick={onClick}>*/}
            {/*  <span className="smr-footer__text">Избранное</span>*/}
            {/*</a>*/}
            {/*<a href="#" className="smr-footer__link" onClick={onClick}>*/}
            {/*  <span className="smr-footer__text">Контакты</span>*/}
            {/*</a>*/}
          </div>
        </div>
        <div className="smr-footer__socials">
          <a href="#" onClick={onClick}>
            <IconFacebook24 />
          </a>
          <a
            href="https://instagram.com/appsmrkz?igshid=ZDdkNTZiNTM="
            target="_blank"
            rel="noreferrer"
            // onClick={onClick}
          >
            <IconInstagram24 />
          </a>
          <a href="#" onClick={onClick}>
            <IconVk24 />
          </a>
          <a
            href="https://t.me/Appsmrkz"
            target="_blank"
            // onClick={onClick}
            rel="noreferrer"
          >
            <IconTelegram24 />
          </a>
          <a href="#" onClick={onClick}>
            <IconYoutube24 />
          </a>
        </div>
        <p className="smr-footer__text">© 2025, СМР</p>
      </Container>
      <ModalTenderCreate
        companyId={!!company ? company.id : null}
        visible={modalTenderCreateVisible}
        closeModal={closeModalTenderCreate}
        tenderCategory={tenderCategory}
      />
    </footer>
  );
}

export default memo(Footer);
